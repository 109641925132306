import React from "react";
import ReleaseComponent from "../../commons/NotesInStatusComments.js";

export default class ConfirmRelease extends React.Component{
constructor(props){
  super(props);
  this.state={
    comments:"",
    submitButtonState: false,
    setResolutionChannel:'Select',
    setText:"Select",
  }
    this._setdata = this._setdata.bind(this);
    this.reason="";
    this.resolutionValue="";
    this.dropDownResult="";
this.promptForComment={
  "Select":"",
  "1-Information Only":"what info provided",
 "2-Could Not Reach":"where left messages",
  "3-Modified PNR":"what modified",
  "4-Disruption Info Incorrect":"explain why disruption info is wrong, ie flight NOT cancelled",
 "5-No Action":"why no action taken"
}
}
// Prevent default action
_handleOnClick(e) {
  e.stopPropagation();
  e.preventDefault();
}

_setResolutionChannel(text) {
  const textvalue={
    "Information Only":"1-Information Only",
    "Left Message, could not reach traveler":"2-Could Not Reach",
    "Changed reservation":"3-Modified PNR",
    "Disruption information incorrect see comments":"4-Disruption Info Incorrect",
     "No Action (duplicate, FIRST didn't contact)":"5-No Action"
  }
  this.setState({setText:text})
  this.setState({setResolutionChannel :textvalue[text]});
  this.reason=this.state.comments;
  this.resolutionValue = this.state.setResolutionChannel
  var submit = this.checkForSubmit.bind(this);
  submit();
}
_handleOnBlur(e){
  if(this.state.setResolutionChannel === 'Select'){
    console.log("error");
  }
}
_handleOnChange(e) {;
    console.log("callling on change");
}
_setdata(text){
  this.setState({
    comments:text
  })
  this.reason=text;
  var submit = this.checkForSubmit.bind(this);
  submit();
}
checkForSubmit(){
  if(this.props.popup==="close"){
    if (checkForSubmit(this.reason) && this.resolutionValue!=="") {
      this.setState({ submitButtonState: true });
    } else {
      this.setState({ submitButtonState: false });
    }
  } else {
    if (checkForSubmit(this.reason)) {
      this.setState({ submitButtonState: true });
    } else {
      this.setState({ submitButtonState: false });
    }
  }
}
onClickSave(){
  if(this.props.popup=="Release"){
    var popOverClass="#notimodalRelease";
  }
  else {
    popOverClass="#notimodalClose";
  }
  if (this.state.submitButtonState) {
    let openChatRecordLocator =this.props.popupdata !==undefined ?  this.props.popupdata.BookingInfo.RecordLocator:'';
    if(this.props.openedChatList !== undefined && this.props.openedChatList !== null &&  this.props.openedChatList !== "" && 
    this.props.openedChatList[openChatRecordLocator] !== undefined && this.props.openedChatList[openChatRecordLocator] !== null && this.props.openedChatList[openChatRecordLocator] !== "") {
      this.props.openedChatList[openChatRecordLocator].close();
    }
    this.props.onClickSave(this.state.comments,this.state.setResolutionChannel);
    window.$(popOverClass).modal('hide');
 }
  else {
    return false;
  }
}

_handleFocus() {
  console.log("calling this");
}

resetForm() {
  this.resolutionValue="";
  this.reason="";
  this.refs.note._resetNotes();
  this.setState({setResolutionChannel:"Select",submitButtonState:false,comments:""})
}

render(){
  //console.log(this.props.status);
  var popupData = this.props.popupdata;
  if(typeof(this.props.popupdata) != "undefined"){
    var popUpName = this.props.userProfileData.Name;
    var EmployeeId= this.props.userProfileData.EmployeeId;
    var popUpClientName = this.props.userProfileData.ClientName;
    var popUpClientId = this.props.userProfileData.ClientId;
    var userProfileData = this.props.userProfileData;
    var intractive = "",popUpHeading="",popOver="",popOut = "";
    var pcc = "";
  
    if(popupData.BookingInfo.Interactive=="true"){
      intractive="Online"
    }else if(popupData.BookingInfo.Interactive=="false"){
      intractive="Offline"
    }

    var source = '';
   if(typeof popupData.BookingInfo.Source != "undefined") {
     source = popupData.BookingInfo.Source;
   } else {
     source = popupData.BookingInfo.AtlasGDS;
   }
 }
 var popoverId="";
 var popoverHeading="";
 if(this.props.popup=="Release"){
   popoverId="notimodalRelease";
   popoverHeading="Confirm Release";
 }else{
   popoverId="notimodalClose";
    popoverHeading="Confirm Close";
 }


if(popupData){
  popUpHeading = (<h4 className="travel-data">{popupData.DepartureAirport}<span className="to-arrow"></span>{popupData.ArrivalAirport}<span className="trip-pnr-info"><br/>{this.props.DispTripId}<br/>{popupData.BookingInfo.RecordLocator}/{popupData.BookingInfo.AtlasGDS}<br/>{popupData.BookingInfo.pcc}/{intractive} </span></h4>);
}
 
return(
<div className="modal fade modal-add" id={popoverId} role="dialog" data-backdrop="static" data-keyboard="false">
  <div className="modal-dialog">
    <div className="modal-content comment_popup">
      <div className="modal-header">
      <div className="col-sm-12">    <button type="button"  className="close" onClick={this.resetForm.bind(this)} data-dismiss="modal">&times;</button>
        <h4 className="modal-title">{popoverHeading}</h4>
      </div>  </div>
      <div  className="modal-body">
        <div className="col-sm-12">
          {!this.props.outOfSync ? <div className="name_container">
            <h4 className="client">{popUpName}</h4>

            <h4 className="employee_id"><span className="color_blue_normal">{EmployeeId}</span> {popUpClientName} {popUpClientName==null || popUpClientId==null? null : ", "}   <span className="client-id"><span>{popUpClientId}</span></span>

            </h4><br/><br/>
          </div>:null}
          <div className="trip row">
            {!this.props.outOfSync ? <div className="side-icon col-sm-1">
              <div className="top-line no-topline"></div>
              <div className={this.props.tripmodeclass}></div>
            </div>:null}
            
            {!this.props.outOfSync ? <div className="travel-details col-sm-8 border_none">
              {popUpHeading}
            </div>:null}

            <div className="clearfix"></div>

            {this.props.popup==="close" && <div className="col-sm-12"><br/>
            <div className="col-sm-12 margin_btm_5 resolution-block margin-class">Result of this PTC Disruption</div>                    <button className="btn btn-default dropdown-toggle custom-resol-dropdown" 
                    type="button" id="menu1" data-toggle="dropdown">{this.state.setResolutionChannel}
                    <span className="caret"></span></button>
                    <ul className="resol-dropdown-menu dropdown-menu" role="menu" aria-labelledby="menu1" 
                    value={this.state.setText} 
                    onClick={this._handleOnClick.bind(this)} 
                    onFocus={this._handleFocus.bind(this)}
                    onBlur={this._handleOnBlur.bind(this)}
                    onChange={this._handleOnChange.bind(this)}>
                        <li className="resol-text" role="presentation" key="1" value="Information Only" onClick={this._setResolutionChannel.bind(this,"Information Only")}><a role="menuitem" tabIndex="-1" href="#">Information Only</a></li>
                        <li className="resol-text" role="presentation" key="2" value="Left Message, could not reach traveler" onClick={this._setResolutionChannel.bind(this,"Left Message, could not reach traveler")}><a role="menuitem" tabIndex="-1" href="#">Left Message, could not reach traveler</a></li>
                        <li className="resol-text" role="presentation" key="2" value="Changed reservation" onClick={this._setResolutionChannel.bind(this,"Changed reservation")}><a role="menuitem" tabIndex="-1" href="#">Changed reservation</a></li>
                        <li className="resol-text" role="presentation" key="2" value="Disruption information incorrect see comments" onClick={this._setResolutionChannel.bind(this,"Disruption information incorrect see comments")}><a role="menuitem" tabIndex="-1" href="#">Disruption information incorrect see comments</a></li>
                        <li className="resol-text" role="presentation" key="2" value="No Action (duplicate, FIRST didn't contact)" onClick={this._setResolutionChannel.bind(this,"No Action (duplicate, FIRST didn't contact)")}><a role="menuitem" tabIndex="-1" href="#">No Action (duplicate, FIRST didn't contact)</a></li>
                    </ul>
                </div>}
            
                <ReleaseComponent ref='note' placeHolder={this.props.placeHolder} notesLabel="Comments:" Data ={this._setdata.bind(this)} prompt={this.promptForComment[this.state.setResolutionChannel]}/>
            <div className="col-sm-6 inline-block pull-right">
            {this.state.submitButtonState ?
              <button className="save_button enabled" id="submitBtn" onClick={this.onClickSave.bind(this)}>Submit</button>:
              <button className="save_button" disabled>Submit</button>
            }
              <button className="button last" data-dismiss="modal" onClick={this.resetForm.bind(this)}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
);
}
}


function checkForSubmit(text) {
  //var CommentText = text.replace(/\s/g,'');
  if (text!= "") {
    //Regex Exp including caracters,Numbers,hypens,commas,periods
    let re = /^[a-zA-Z0-9\n .,-]{3,90}$/g;
    return re.test(text);
  } else {
    return false;
  }
}
